import type { FunctionComponent } from 'react';
import type { BentoTwoFragment } from '../../../../graphql/queries/GetBentoTwo.graphql';
import BentoPieceRectangle from '../../pieces/bento-piece-rectangle';
import BentoPieceSquare from '../../pieces/bento-piece-square';
import styles from './bento-two.module.scss';
export const BentoTwo: FunctionComponent<Props> = ({
  pieces
}) => {
  const pieceOne = pieces?.items[0];
  const pieceTwo = pieces?.items[1];
  return <div className={styles.container} data-sentry-component="BentoTwo" data-sentry-source-file="bento-two.tsx">
            <div className={styles.flexContainer}>
                <div className={styles.boxOne}>
                    {pieceOne?.type === 'BentoPieceSquare' && <BentoPieceSquare square={pieceOne} />}
                    {pieceOne?.type === 'BentoPieceRectangle' || pieceOne?.type === 'BentoPieceRectangleBroadbandFacts' && <BentoPieceRectangle rectangle={pieceOne} />}
                </div>
                <div className={styles.boxTwo}>
                    {pieceTwo?.type === 'BentoPieceSquare' && <BentoPieceSquare square={pieceTwo} />}
                    {pieceTwo?.type === 'BentoPieceRectangle' || pieceTwo?.type === 'BentoPieceRectangleBroadbandFacts' && <BentoPieceRectangle rectangle={pieceTwo} />}
                </div>
            </div>
        </div>;
};
export default BentoTwo;
type Props = Omit<BentoTwoFragment, 'sys' | 'type'> & {
  index?: number;
};